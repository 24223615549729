import React from 'react'

const Contact = () => {
  return (
    <div className="text-white text-center text-3xl p-40">
      Contact
    </div>
  )
}

export default Contact
