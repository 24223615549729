import React from 'react'

const Blog = () => {
  return (
    <div className="text-white text-center text-3xl p-40">
      Blog
    </div>
  )
}

export default Blog
